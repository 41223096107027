<template>
  <div>
    <!-- <div id="myChart" style="width: 600px; height: 400px"></div> -->
    <div class="welcome-container">
      <div class="bg"></div>
      <el-row class="row">
        <el-col class="h1">暂未开放，敬请期待</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app',
  methods: {
    drawChart () {
      // 基于准备好的dom，初始化echarts实例【这里存在一个问题，请看到最后】
      const myChart = this.$echarts.init(document.getElementById('myChart'))
      var option = {
        title: {
          text: 'ECharts 入门示例'
        },
        tooltip: {},
        legend: {
          data: ['销量']
        },
        xAxis: {
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      }
      myChart.setOption(option)
    }
  },
  mounted () {
    // this.drawChart()
  }
}
</script>

<style lang="less" scoped>
.welcome-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 40px;
}
.bg {
  width: 100%;
  height: 100%;
  background-image: url(.././../../../assets/img/newerp_icon1.png);
  background-size: auto 90%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.08;
  position: absolute;
  top: 0;
  right: 0;
}
.txt {
  font-size: 60px;
  font-weight: 600;
  color: #464545;
  padding-bottom: 120px;
}
.h1 {
  font-weight: 400;
  font-size: 32px;
  color: #464545;
  margin-bottom: 40px;
  text-align: center;
}
.row {
  width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
